@import "../../GlobalStyles/mixins.scss";
@import "../../GlobalStyles/variables.scss";

.proDetail {
  &-header {
    @include flexCenterBetween;
    @include proContainer;
    padding: 0.5rem 1rem 0;
    &-title {
      @include flexCenterBetween;
      gap: 0.6rem;
      @include mobile {
        gap: 0.3rem;
        flex-wrap: wrap;
      }
      h1 {
        font-size: $font_xl;
        font-weight: 500;
        color: $color_black_white;
        @include mobile {
          font-size: $font_m;
          width: 100%;
          &.mNoFull {
            width: auto;
          }
        }
      }
      h2 {
        color: $sub-header-color;
        font-weight: normal;
        font-size: $font_s;
        @include mobile {
          font-size: $font_xs;
          span {
            display: none;
          }
        }
      }
    }
    &-back {
      button {
        min-width: 40px;
        @include mobile {
          .MuiButton-icon {
            margin-right: 0;
          }
        }
      }
    }
    &-wrapper {
      border-bottom: 1px solid $form_border_color;
    }
  }
  &-tabs {
    @include proContainer;
    .subheader {
      justify-content: left;
    }
  }
}
.detail-sub-header {
  padding: 1rem;
}
