.demo-dashboard {
  // TODO change color
  background: var(--dashboard-background) !important;
  display: flex;
  gap: 16px;
  padding: 24px 0;
  height: calc(100vh - 100px);
  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: unset;
  }
  *{
    font-family: "Poppins", sans-serif;
  }
  &-wrapper {
    background: var(--dashboard-background);
    height: calc(100vh - 100px);
  }
  &-left {
    width: 26%;
    min-width: 360px;
    height: 100%;
    @media (max-width: 768px) {
      width: 100%;
      min-width: unset;
    }
  }
  &-right {
    width: 74%;
    padding: 0 2px;
    overflow: auto;
    margin-bottom: -3px;
    padding-bottom: 3px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &-bottom {
    display: flex;
    gap: 16px;
    .project-rise-wrapper {
      flex-grow: 1;
      width: 100%;
      margin: 16px auto 0;
    }
    @media (max-width: 1520px) {
      flex-wrap: wrap;
    }
    &-left {
      width: 40%;
      display: flex;
      flex-flow: column;
      &.with-hidden-heatmap {
        width: 100%;
        display: flex;
        flex-flow: unset;
        gap: 16px;
        flex-wrap: wrap;
        .djr-Wrapper {
          width: calc(50% - 8px);
          margin-top: 0px !important;
        }
        .djr-chart {
          height: 300px;
        }
        .journey-ratio-ring-wrapper {
          top: 36px;
        }
      }
      @media (max-width: 1520px) {
        width: 100%;
        display: flex;
        align-items: flex-end;
        gap: 16px;
        flex-wrap: wrap;
        flex-flow: inherit;
        .djr-Wrapper {
          width: calc(50% - 8px);
        }
      }
      @media (max-width: 1220px) {
        .djr-Wrapper {
          width: 100% !important;
        }
      }
    }
    &-right {
      width: 60%;
      .djr-Wrapper {
        height: 100%;
      }
      @media (max-width: 1520px) {
        width: 100%;
        .djr-Wrapper {
          height: unset;
        }
      }
    }
  }
}

.djr {
  &-Wrapper {
    background: var(--card-box-background);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  &-header {
    border-bottom: 1px solid #e5e5ef;
    padding: 16px 16px 0;
    &-title {
      color: var(--header-title-color);
      font-size: 20px;
      font-weight: 600;
    }
    &-subtitle {
      color: var(--subtitle-color);
      font-size: 16px;
    }
    &.without-border {
      border-bottom: none;
    }
  }
  &-chart {
    padding: 16px;
    height: 432px;
    @media (max-width: 1520px) {
      height: 300px;
    }
    @media (max-width: 768px) {
      height: 260px;
    }
  }
  &-info-card {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 50%;
    padding: 6px;
    border-radius: 4px;
    &.red {
      color: #fff;
      background: #f5010b;
    }
    &.green {
      color: #007421;
      background: #e6ffed;
    }
    &.blue {
      color: #1465c0;
      background: #c9e6ff;
    }
    &-label {
      max-width: calc(100% - 18px);
      font-size: 13px;
    }

    &-wrapper {
      display: flex;
      padding: 12px;
      gap: 10px;
      border-top: 1px solid #e5e5ef;
    }
  }

  &-risk {
    padding: 16px 16px 25px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      padding: 16px 0 16px;
    }
    &-card {
      background-color: var(--card-title-wrapper-color);
      width: 50%;
      border-right: 1px solid var(--card-title-wrapper-border);
      border-top: 1px solid var(--card-title-wrapper-border);
      &:nth-child(2n) {
        border-right: none;
      }
      &:nth-child(-n + 2) {
        border-top: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &-title {
        font-size: 14px;
        color: var(--card-title-wrapper-title-color);
        &-wrapper {
          display: flex;
          justify-content: space-between;
          padding: 16px 16px 0;
          min-height: 52px;
          line-height: 20px;
        }
      }
      &-value {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 12.5px;
        padding: 3px 5px;
        width: 60px;
        text-align: center;
        &.red {
          color: #cd0000;
          background: #ffcfcf;
        }
        &.orange {
          color: #db5c00;
          background: #ffe3cf;
        }
        &.green {
          color: #007421;
          background: #dfffe2;
        }
      }
      &-icon {
        padding: 16px 16px 30px;
        display: flex;
        justify-content: center;
      }
    }
  }
  &-heat-map {
    padding: 16px;
  }
}
