@import "../../../GlobalStyles/mixins.scss";
@import "../../../GlobalStyles/variables.scss";

.model-title {
  font-family: $font_family;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.field-label {
  font-family: $font_family !important;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  max-width: 200px;
}
.title {
  font-family: $font_family;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.input-fields {
  border-radius: 6px !important;
  .MuiInputBase-multiline {
    padding: 8px !important;
  }
  .MuiOutlinedInput-root {
    border-radius: 6px !important;
  }
  .MuiOutlinedInput-input {
    border-radius: 6px !important;
    padding: 8px 8px !important;
  }
}
.status-button {
  background-color: #ff9900 !important;
  font-weight: bold !important;
  border-radius: 12px !important;
  padding: 2px 20px !important;
  font-size: 0.875rem !important;
}
.status-button.MuiButton-textPrimary {
  color: white !important;
}
