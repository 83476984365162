@import "../../../GlobalStyles/mixins.scss";
@import "../../../GlobalStyles/variables.scss";

.header {
  &_button {
    width: 100%;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  width: 100%;
  height: 100px;
  border-bottom: 1px solid $primary_border;
  box-shadow: $primary_boxshadow;

  .proInputWrapper .MuiInputBase-root{
    background: #ffffff !important;
    border: 1px solid gray;
    
  }

  .proInputWrapper .MuiInputBase-root:hover{
    border: none
  }

  .proInputWrapper .MuiOutlinedInput-input{
    background: #ffffff !important;
  }

  .proInputWrapper .MuiOutlinedInput-input:hover{
   border: none
  }

  

  &_main {
    @include flexJCenter;
    background-color: #fff;
  }

  &_container {
    width: calc(100% - 96px);
    @include flexCenterBetween;
  }

  &_logo {
    width: auto;
    display: flex;

    img {
      height: 56px;
      object-fit: contain;
      object-position: center;
      padding: 5px;
    }

    &_menu-open {
      display: none;

      img {
        width: $pixel_24 !important;
        height: $pixel_24 !important;
      }
    }
  }

  &_menus {
    width: 65%;

    &_search {
      @include flexrowacenter;
      width: 100%;

      &_input {
        @include flexrowacenter;
        position: relative;
        width: 35%;

        input {
          width: 100%;
          padding: $pixel_8 36px $pixel_8 $font_xxl;
          color: $third_grey;
          font-size: $font_s;
          border-radius: $default_border_radius;
          border: 1px solid $third_grey;
          font-weight: 400;
          outline: none;
        }
      }

      &_icon1 {
        position: absolute;
        left: 8px;
        @include flexrowacenter;
      }

      &_icon2 {
        position: absolute;
        right: 8px;
        @include flexrowacenter;
      }
    }
  }

  &_user {
    @include flexrowacenter;

    &_sec {
      padding: 0 0 0 10px;
      @include mobile {
        padding: 0 0 0 4px;
      }

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    &_popover {
      width: 220px;
      border-radius: $default_border_radius;
      box-shadow: $primary_boxshadow;

      &_profile {
        display: flex;
        padding: $pixel_8;
        background: $header_profile_bg;

        &_info {
          padding: 0 $pixel_8;

          &_name {
            font-size: $font_xs;
            color: $header_text;
          }

          &_email {
            font-size: $font_xxs;
            color: $header_text;
          }
        }
      }

      &_myAccount {
        font-size: $pixel_16;
        @include flexCenterBetween;
        padding: $pixel_16;
        cursor: pointer;
        width: 100%;
      }
      &_logout {
        width: 100%;
        @include flexCenter;
        padding: $pixel_8 0;
        color: $link_color;
        cursor: pointer;
        font-size: $pixel_16;
        font-weight: 500;
      }
    }
  }

  &_subheader {
    @include flexJCenter;
    background: $sub_header_background;
    height: 43px;
  }
}

@include mobile {
  .header {
    height: 60px;

    &_container {
      width: calc(100% - 20px);
      padding: 10px 0;
    }

    &_logo {
      @include flexrowacenter;

      img {
        height: 38.5px;
        padding: 3px;
      }

      &_menu-open {
        display: block;
      }
    }

    &_menus {
      display: none;
    }

    &_user {
      &_sec:first-child {
        padding: 0;
      }

      &_sec {
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    &_subheader {
      display: none;
      position: absolute;
      width: 100%;
      top: 60px;
      left: 0;
      height: calc(100dvh - 60px);
      z-index: 100;
      overflow: auto;

      &_main {
        width: calc(100% - 30px);
      }
    }

    &_subheader.open {
      display: flex;
    }
  }
}

.avatarProfile.MuiAvatar-root {
  width: 24px;
  height: 24px;
}
