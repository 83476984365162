:root {
  --background-color: #ffffff;
  --primary_white: #ffffff;
  --third_black: #071013;
  --primary_background: #ffffff00;
  --primary_grey: #5d6976;
  --secondary_grey: #030303;
  --third_grey: #1c1c1c58;
  --primary_border: #e6e6e6;
  --secondary_border: #d6ddea;
  --third_border: #e4e4e4;
  --primary_green: #30a900;
  --primary_blue: #00b0ee;
  --color_bg2: #fff0e5;
  --color_bg3: #ebffe3;
  --primary_boxshadow: 0px 0px 29.4px 0px #23424d0d;
  --secondary_boxshadow: 0px 2px 8px 0px #0000005c;

  // link
  --link_color: #1b59f8;

  // header
  --header_bg: #ffffff;
  --header_profile_bg: #1f88e6;
  --header_text: #fff;
  --sub_header_text: #000;
  --section_header_text: #000;
  --sub_header_text_active: #1f88e6;
  --header_border_active: #fff;
  --sub-header-color: #868686;
  --sub_header_background: #1f88e6;

  //table
  --table_background: #e3e3e3;
  --table_row_hover: #0000000a;
  --table_border: #e8e8e8;
  --table_cell_text: #000000;
  --table_search_background: #ffffff;
  --table_search_border: #d6ddea;
  --pagination_background: #e3e3e3;
  --pagination_background_active: #1f88e6;
  --pagination_text: #000;
  --pagination_text_active: #ffffff;
  --pagination_button_disable: #bababa;
  --pagination_button_enable: #00b0ee;
  --pagination_black_white: #000000;
  --delete_cell_background: #ffffff;
  --delete_cell_border: #d6ddea;
  --link_color_table: #1b59f8;

  --secondary_text: #535353;
  --card_primary_bg: #ffffff00;
  --primary-btn-bg: #1f88e6;
  --primary-btn-text: #ffffff;
  --secondary-btn-bg: #d6ddea;
  --secondary-btn-text: #535353;
  --third-btn-text: #1b59f8;
  --third-btn-bg: #ffffff;
  --form-border-color: #e2e8f0;
  --form-border-color-primary: #e2e8f0;
  --form-value-color: #00050b;
  --form_value_background: #ffffff;
  --form-label-color: #535353;
  --form_label_background: #ffffff;
  --form-focus-color: #00b0ee;
  --form-error-color: #ef0c0c;

  --pre_login_bg: #e9f9ff;
  --pre_login_layout_bg: #e9f9ff;
  --pre_login_card_bg: #ffffff;
  --pre_login_primary_text: #000000;
  --pre_login_secondary_text: #707070;
  --pre_login_link_text: #1b59f8;
  --grid-no-rows: #cbd5e1;
  --scroll-background: #f5f5f5;
  --scroll-thumb: #868686;
  --checkable-input-icon: #00b0ee;
  --file-placeholder: #a2a2a2;

  --dashboard_top_h2: #000000;
  --color_black_white: #000000;
  --color_black_grey1: #000000;
  --color_black_grey2: #000000;

  --dashboard_secondary_color: #00050b;
  --tab_dropdown_background: #ffffff;
  --autocomplete_tab_color: #000000;
  --autocomplete_tab_background: #ebebeb;
  --file-placeholder-size: 14px;
  --form-font-size: 14px;
  --footer-font-color: #000000;
  --certified_payroll_table_bg: #e6f5f9;
  --certified_payroll_table_cell_text: #000000;
  --certified_payroll_table_row_bg: #ffffff;
  --export_icon_color: #1b59f8;

  // Font color for light mode
  --font-color: #000;
  --subtitle-color: #9291a5;
  --muitable-color: #000000de;
  --feed-option-background: #e5f3ff;
  --card-title-wrapper-color: #ffffff;
  --card-title-wrapper-border: #e5e5ef;
  --card-title-wrapper-title-color: #000;

  --report-table-background-color: #e3e3e3;
  --report-table-row-even-background-color: #f9f9f9;
  --report-table-row-odd-background-color: #fff;
  --report-table-footer-background-color: #e3e3e3;
  --report-table--export-color: #35353a;
  --report-table-footer-color: #6e6e71;
  --dashboard-table-background-color: #0996ff;
  --dashboard-table-row-odd-background-color: #f9f9f9;
  --dashboard-table-row-even-background-color: #fff;
  --border-report: #d6ddea;
  --demo_chart_background: #fff;
  --demo_chart_card_heading: #000;
  --webkit_scroller: #fff;
  --webkit_scroller_thumb: #00b0ee;

  @include mobile {
    --form-font-size: 16px;
  }
}

[data-theme-ui="dark"] {
  --background-color: #222243;
  --third_black: #ffffff;
  --primary_background: #202328;
  --primary_grey: #b5b7c8;
  --secondary_grey: #b5b7c8;
  --third_grey: #36384358;
  --primary_border: #e6e6e6;
  --secondary_border: #ffffff;

  --third_border: #1e2027;
  --primary_green: #30a900;
  --primary_blue: #00b0ee;
  --color_bg2: #3f3b2f;
  --color_bg3: #ebffe3;
  --primary_boxshadow: 0px 0px 29.4px 0px #5598b116;
  --secondary_boxshadow: 0px 2px 8px 0px #aaaaaa5c;
  --link_color: #1b59f8;
  --link_color_table: #00b0ee;
  --secondary_text: #535353;
  --sub-header-color: #868686;
  --sub_header_background: #2e2e48;
  --card_primary_bg: #15171c;
  --primary-btn-bg: #00b0ee;
  --primary-btn-text: #ffffff;
  --secondary-btn-bg: #d6ddea;
  --secondary-btn-text: #868686;
  --third-btn-text: #1c1c1c;
  --third-btn-bg: #ffffff;
  --form-border-color: #fff;
  --form-border-color-primary: #363843;
  --form-value-color: #ffffff;
  --form_value_background: #2E2E48;
  --form-label-color: #fff;
  --form_label_background: #00050b;
  --form-focus-color: #00b0ee;
  --form-error-color: #ef0c0c;
  --header_profile_bg: #222243;
  --header_icon_active: #00b0ee;
  --header_icon_default: #00050b;
  --header_text: #fff;
  --sub_header_text: #fff;
  --header_text_tertiary: #ffffff;
  --header_bg: #222243;
  --header_border_active: #00b0ee;
  --pre_login_bg: #e9f9ff;
  --pre_login_layout_bg: #000000;
  --pre_login_card_bg: #15171c;
  --pre_login_primary_text: #ffffff;
  --pre_login_secondary_text: #ffffff;
  --pre_login_link_text: #00b0ee;
  --grid-no-rows: #cbd5e1;
  --scroll-background: #f5f5f5;
  --scroll-thumb: #868686;
  --checkable-input-icon: #00b0ee;
  --file-placeholder: #a2a2a2;

  --dashboard_top_h2: #ffffff;
  --color_black_white: #ffffff;
  --color_black_grey1: #6d7080;
  --color_black_grey2: #b5b7c8;
  --pagination_background: #202328;
  --pagination_background_active: #00b0ee;
  --table_background: #2e2e48;
  --table_border: #202328;
  --table_search_background: #202328;
  --table_search_border: #363843;
  --table_cell_text: #ffffff;
  --table_row_hover: #5d6976;
  --pagination_text: #ffffff;
  --pagination_text_active: #ffffff;
  --pagination_black_white: #ffffff;
  --pagination_button_disable: #ffffff;
  --pagination_button_enable: #00b0ee;
  --delete_cell_background: #202328;
  --delete_cell_border: #202328;
  --dashboard_secondary_color: #ffffff;
  --tab_dropdown_background: #202328;
  --autocomplete_tab_color: #ffffff;
  --autocomplete_tab_background: #5d6976;
  --footer-font-color: #ffffff;
  --certified_payroll_table_bg: #202328;
  --certified_payroll_table_cell_text: #ffffff;
  --certified_payroll_table_row_bg: #000000;
  --export_icon_color: #202328;

  --font-color: #fff;
  --card-box-background: #2e2e48;
  --dashboard-background: #222243;
  --subtitle-color: #fff;
  --muitable-color: #fff;
  --header-title-color: #fff;
  --feed-option-background: #e5f3ff;
  --video-color-icon: #fff;
  --card-title-wrapper-color: #383854;
  --card-title-wrapper-title-color: #ffffff;
  --table-background-color: #2e2e48;
  --search-input-color: #fff;
  --report-table-background-color: #2e2e48;
  --dashboard-table-background-color: #2e2e48;
  --report-table-row-even-background-color: #2e2e48;
  --report-table-row-odd-background-color: #2e2e48;
  --report-table-footer-background-color: #2e2e48;
  --dashboard-table-row-odd-background-color: #2e2e48;
  --dashboard-table-row-even-background-color: #2e2e48;
  --report-table--export-color: #fff;
  --report-table-footer-color: #fff;
  --border-report: none;
  --section_header_text: #fff;
  --demo_chart_background: #2E2E48;
  --demo_chart_card_heading: #fff;
  --webkit_scroller: #2e2e48;
  --webkit_scroller_thumb: #fff;
}