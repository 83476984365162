@import "../../../GlobalStyles/variables.scss";
@import "../../../GlobalStyles/mixins.scss";

.report-grid {
  max-height: calc(100dvh - 100px);

  .MuiDataGrid-root {
    border: none;
  }

  .MuiDataGrid-columnHeaders {
    background-color: var(--report-table-background-color);
  }

  .MuiDataGrid-row:nth-of-type(odd) {
    background-color: var(--report-table-row-odd-background-color) !important;
    border: 1px soild var(--border-report);
  }

  .MuiDataGrid-row:nth-of-type(even) {
    background-color: var(--report-table-row-even-background-color) !important;
    border: 1px soild var(--border-report);
  }

  .MuiDataGrid-row {
    max-height: none !important;
  }

  .MuiDataGrid-columnHeaderTitle{
    height: auto !important;
  }

  .MuiTablePagination-displayedRows{
    color: var(--report-table-footer-color);
  }

  .MuiDataGrid-cell {
    max-height: none !important;
    padding: 10px;
    text-align: left;
    display: flex;
    align-items: flex-start;
  }

  .MuiDataGrid-cell > div {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    justify-content: center;
    color: var(--color_black_white);
  }

  .MuiDataGrid-cell > div > div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .proGrid {
    &-header {
      background: $table_background;
      color: $color_black_white;
    }

    &-title {
      font-weight: bold;
      font-size: $font_l;
      color: $color_black_white;
      flex-grow: 1;

      @include mobile {
        font-size: 15px;
      }

      &-wrapper {
        @include flexCenterBetween;
        width: 100%;
        padding: 1rem 0 0.5rem;
        flex-wrap: wrap;
        gap: 1rem;

        &-with-border {
          border-top: 1px solid #adadad;
          margin-bottom: 10px;
        }

        &-no-border {
          border: none;
        }

        @include mobile {
          gap: 0.5rem;
        }
      }
    }

    &-filter {
      @include flexCenter;
      gap: 1rem;

      .date-select {
        button:not(.MuiButton-containedPrimary) {
          border: none;
          color: #0000008a !important;
          background: none !important;
        }
      }

      button:not(.MuiButton-containedPrimary) {
        border: 1px solid $secondary_border;
        font-size: 14px;
        padding: 6px 16px;
        text-transform: capitalize;
        border-radius: $default_border_radius;
        color: var(--report-table--export-color) !important;
        .MuiButton-startIcon {
          color: var(--report-table--export-color) !important;
        }
      }

      .export-icon {
        color: var(--report-table--export-color) !important;

        svg {
          color: var(--report-table--export-color) !important;
        }
      }

      @include mobile {
        gap: 0.5rem;
        width: 100%;
        justify-content: end;
        flex-wrap: wrap;
      }
    }

    &-search-wrapper {
      & .MuiInputBase-root {
        border-radius: $default_border_radius;
        background: $table_search_background;
        border: 1px solid $table_search_border;
        padding: 1px 6px;
        width: 380px;

        @include mobile {
          width: 150px;
        }

        .MuiSvgIcon-root {
          color: $color_black_white;
        }

        .MuiInputBase-input {
          color: $color_black_white;
          font-weight: 400 !important;
        }

        &::before,
        &::after {
          display: none;
        }
      }
    }

    &-no-rows {
      @include flexCenter;
      color: $color_black_white;
      height: 100%;
      border-bottom: 1px solid $secondary_border;
    }
  }

  .proGrid-table-cell {
    color: $table_cell_text;
    background-color: var(--table-background-color);
    border-color: $table_border !important;
  }

  .MuiDataGrid-row:hover {
    background: $table_row_hover !important;
  }

  .MuiDataGrid {
    &-footerContainer {
      border-top: none;
      background-color: var(--report-table-footer-background-color) !important;
      justify-content: flex-end;
    }

    .MuiTablePagination-root {
      color: var(--color_black_white) !important;
    }


    &-virtualScroller {
      border-right: 1px solid $table_border;
      border-left: 1px solid $table_border;
      background-color: var(--report-table-row-background-color);
      @include hideScrollBar;
    }

    &-overlayWrapper {
      min-height: 44px;
    }

    &-selectedRowCount {
      display: none;
    }
  }

  &.v2 {
    .MuiDataGrid-rowCount {
      display: none;
    }
  }
}

.collapsed {
  max-width: 1340px;
}
