@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/mixins.scss";

.rs-grid-wrapper {
  @include proContainer;
  max-height: calc(100dvh - 100px);

  .MuiDataGrid-root {
    border: none;
  }

  .proGrid {
    &-header {
      background: $table_background;
      color: $color_black_white;
      .MuiDataGrid-columnHeaderTitle {
        height: auto !important;
      }
    }

    &-title {
      font-weight: bold;
      font-size: $font_l;
      color: $color_black_white;
      flex-grow: 1;

      @include mobile {
        font-size: 15px;
      }

      &-wrapper {
        @include flexCenterBetween;
        width: 100%;
        padding: 0.3rem 0 0.5rem;
        flex-wrap: wrap;
        gap: 1rem;

        @include mobile {
          gap: 0.5rem;
        }
      }
    }

    &-filter {
      @include flexCenter;
      gap: 1rem;

      .date-select {
        button:not(.MuiButton-containedPrimary) {
          border: none;
          color: #0000008a !important;
          background: none !important;
        }
      }

      button:not(.MuiButton-containedPrimary) {
        border: 1px solid $secondary_border;
        font-size: 14px;
        padding: 6px 16px;
        text-transform: capitalize;
        border-radius: $default_border_radius;
        color: $third_btn_text !important;
        background: $third_btn_bg !important;
      }

      @include mobile {
        gap: 0.5rem;
        width: 100%;
        justify-content: end;
        flex-wrap: wrap;
      }
    }

    &-search-wrapper {
      & .MuiInputBase-root {
        border-radius: $default_border_radius;
        background: $table_search_background;
        border: 1px solid $table_search_border;
        padding: 1px 6px;
        width: 380px;

        @include mobile {
          width: 150px;
        }

        .MuiSvgIcon-root {
          color: $color_black_white;
        }

        .MuiInputBase-input {
          color: $color_black_white;
          font-weight: 400 !important;
        }

        &::before,
        &::after {
          display: none;
        }
      }
    }

    &-no-rows {
      @include flexCenter;
      color: $color_black_white;
      height: 100%;
      border-bottom: 1px solid $secondary_border;
    }
  }

  .proGrid-table-cell {
    color: $table_cell_text;
    border-color: $table_border !important;
    background-color: var(--table-background-color);
  }

  .MuiTablePagination-displayedRows {
    display: none;
  }

  .MuiDataGrid-row:hover {
    background: $table_row_hover !important;
  }

  .MuiDataGrid {
    &-footerContainer {
      border-top: none;
      justify-content: center;

      .MuiTablePagination-toolbar {
        padding: 0;
      }

      .MuiTablePagination-root {
        @include hideScrollBar;
      }

      .MuiTablePagination-actions {
        margin-left: 0;
      }

      .MuiPaginationItem-page {
        background: $pagination_background !important;
        color: $pagination_text !important;
      }

      .MuiPaginationItem-page.Mui-selected {
        background: $pagination_background_active !important;
        color: $pagination_text_active !important;
      }

      .MuiPaginationItem-root.MuiPaginationItem-text.Mui-disabled {
        color: $pagination_text !important;
      }
    }

    &-virtualScroller {
      border-right: 1px solid $table_border;
      border-left: 1px solid $table_border;
      @include hideScrollBar;
    }

    &-overlayWrapper {
      min-height: 44px;
    }

    &-selectedRowCount {
      display: none;
    }
  }

  &.v2 {
    .MuiDataGrid-rowCount {
      display: none;
    }
  }
}

.collapsed {
  max-width: 1340px;
}

.export-icon {
  color: $export_icon_color !important;

  svg {
    color: $export_icon_color !important;
  }
}