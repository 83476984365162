.journey-ratio {
  &-chart {
    position: relative;
    height: 100%;
  }
  &-ring {
    &-wrapper {
      position: absolute;
      width: 100%;
      top: 86px;
      @media (max-width: 1520px) {
        top: 36px;
      }
    }
    &-outer {
      margin: auto;
      max-width: 360px;
      height: 180px;
      background: linear-gradient(270deg, #427803 0%, #518d09 50%, #f3040c 100%);
      border-radius: 500px 500px 0 0;
      padding-top: 10px;
      @media (max-width: 768px) {
        max-width: 280px;
        height: 140px;
      }
    }
    &-gap {
      background: #ffffff;
      width: calc(100% - 40px);
      height: calc(100% - 10px);
      border-radius: 500px 500px 0 0;
      padding-top: 10px;
      margin: 10px auto 0;
    }
    &-inner {
      width: calc(100% - 150px);
      height: calc(100% - 60px);
      border-radius: 500px 500px 0 0;
      background: linear-gradient(270deg, #427803 0%, #518d09 50%, #f3040c 100%);
      margin: 60px auto 0;
      @media (max-width: 768px) {
        width: calc(100% - 90px);
        height: calc(100% - 35px);
        margin: 35px auto 0;

      }
    }
  }
  &-inner {
    &-arrow {
      position: absolute;
      bottom: 50%;
      left: 50%;
      display: flex;
      height: 320px;
      transition: all 0.3s ease;
    }
    &-circle {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 100px;
      height: 100px;
      background: #417703;
      border-radius: 50%;
      box-shadow: 0 0 0 5px white, 0 0 0 8px #417703;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      font-weight: 600;
      color: #fff;
    }
  }
  &-standard {
    position: absolute;
    top: -36px;
    left: 50%;
    transform: translate(-50%);
    color: white;
    background: #000;
    padding: 2px 20px;
    border-radius: 5px;
    font-size: 14px;
    &-ring {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 15px;
      height: 15px;
      background: #1f88e6;
      border: 2px solid white;
      border-radius: 50%;
      border: 2px solid white;
      border-radius: 50%;
    }
  }
}
