@import "../../GlobalStyles/mixins.scss";
@import "../../GlobalStyles/variables.scss";

.dashboard-top {
  padding: $font_xs $font_xl;
  @include flexrowacenter;
  border-radius: $default_border_radius;
  &_left {
    padding: 0 $font_sm 0 0;
    @include flexrowacenter;
    img {
      width: $pixel_40;
      height: $pixel_40;
    }
  }
  &_right {
    h5 {
      font-size: $font_xs;
      font-weight: 400;
      color: $secondary_grey;
    }
    h2 {
      font-size: $font_l;
      font-weight: 600;
      color: $dashboard_top_h2;
    }
  }
}