@import "../../../GlobalStyles/mixins.scss";
@import "../../../GlobalStyles/variables.scss";

.dashboardCard-top {
  margin-top: 20px;
  padding: $font_l $font_m;
  @include colflexcenter;
  border-radius: $default_border_radius;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  background-position: center;
  &_left {
    padding: 0 $font_sm 0 0;
    @include flexrowacenter;
    img {
      width: $pixel_40;
      height: $pixel_40;
    }
  }
  &_right {
    h5 {
      font-size: $font_l;
      font-weight: 600;
      color: #ffffff;
      margin-top: 10px;
    }
    h2 {
      font-size: $font_xs;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
