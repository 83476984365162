// colors
$body_background: var(--background-color);
$primary_white: var(--primary_white);
$third_black: var(--third_black);
$primary_background: var(--primary_background);
$primary_grey: var(--primary_grey);
$secondary_grey: var(--secondary_grey);
$third_grey: var(--third_grey);
$primary_border: var(--primary_border);
$secondary_border: var(--secondary_border);
$third_border: var(--third_border);
$primary_green: var(--primary_green);
$primary_blue: var(--primary_blue);
$color_bg2: var(--color_bg2);
$color_bg3: var(--color_bg3);

// link
$link_color: var(--link_color);

// header 
$header_profile_bg: var(--header_profile_bg);
$header_bg: var(--header_bg);
$header_text: var(--header_text);
$sub_header_text: var(--sub_header_text);
$section_header_text: var(--section_header_text);
$sub_header_text_active: var(--sub_header_text_active);
$header_border_active: var(--header_border_active);
$sub_header_background: var(--sub_header_background);
$sub-header-color: var(--sub-header-color);

// table
$table_row_hover: var(--table_row_hover);
$table_background: var(--table_background);
$table_search_background: var(--table_search_background);
$table_search_border: var(--table_search_border);
$table_border: var(--table_border);
$pagination_background: var(--pagination_background);
$pagination_background_active: var(--pagination_background_active);
$pagination_text: var(--pagination_text);
$pagination_text_active: var(--pagination_text_active);
$pagination_button_disable: var(--pagination_button_disable);
$pagination_button_enable: var(--pagination_button_enable);
$delete_cell_background: var(--delete_cell_background);
$delete_cell_border: var(--delete_cell_border);
$link_color_table: var(--link_color_table);

$secondary_text: var(--secondary_text);
$card_primary_bg: var(--card_primary_bg);
$primary_boxshadow: var(--primary_boxshadow);
$secondary_boxshadow: var(--secondary_boxshadow);
$primary_btn_bg: var(--primary-btn-bg);
$primary_btn_text: var(--primary-btn-text);
$secondary_btn_bg: var(--secondary-btn-bg);
$secondary_btn_text: var(--secondary-btn-text);
$third_btn_text: var(--third-btn-text);
$third_btn_bg: var(--third-btn-bg);
$form_border_color: var(--form-border-color);
$form_border_color_primary: var(--form-border-color-primary);
$form_value_color: var(--form-value-color);
$form_label_color: var(--form-label-color);
$form_label_background: var(--form_label_background);
$form_value_background: var(--form_value_background);
$form_focus_color: var(--form-focus-color);
$form_error_color: var(--form-error-color);

$pre_login_bg: var(--pre_login_bg);
$pre_login_layout_bg: var(--pre_login_layout_bg);
$pre_login_card_bg: var(--pre_login_card_bg);
$pre_login_primary_text: var(--pre_login_primary_text);
$pre_login_secondary_text: var(--pre_login_secondary_text);
$pre_login_link_text: var(--pre_login_link_text);
$grid_no_rows: var(--grid-no-rows);
$scroll_background: var(--scroll-background);
$scroll_thumb: var(--scroll-thumb);
$checkable_input_icon: var(--checkable-input-icon);
$file_placeholder: var(--file-placeholder);
$file_placeholder_size: var(--file-placeholder-size);

$dashboard_top_h2: var(--dashboard_top_h2);
$color_black_white: var(--color_black_white);
$color_black_grey1: var(color_black_grey1);
$color_black_grey2: var(color_black_grey1);
$table_cell_text: var(--table_cell_text);
$pagination_black_white: var(--pagination_black_white);
$dashboard_secondary_color: var(--dashboard_secondary_color);

$tab_dropdown_background: var(--tab_dropdown_background);
$autocomplete_tab_color: var(--autocomplete_tab_color);
$autocomplete_tab_background: var(--autocomplete_tab_background);
$footer_font_color: var(--footer-font-color);
$certified_payroll_table_bg: var(--certified_payroll_table_bg);
$certified_payroll_table_cell_text: var(--certified_payroll_table_cell_text);
$certified_payroll_table_row_bg: var(--certified_payroll_table_row_bg);
$export_icon_color: var(--export_icon_color);
$demo_chart_background: var(--demo_chart_background);
$demo_chart_card_heading: var(--demo_chart_card_heading);
$webkit_scroller_thumb: var(--webkit_scroller_thumb);
$webkit_scroller: var(--webkit_scroller);

//Font-family
$font_family: "Poppins", sans-serif;

$form_font_size: var(--form-font-size);
$form_read_label_size: 12px;
$form_read_value_size: 16px;

//Font-size
$font_xxl: 28px;
$font_xl: 24px;
$font_l: 22px;
$font_m: 18px;
$font_sm: 16px;
$font_s: 14px;
$font_xs: 12px;
$font_xxs: 10px;

//Pixels - Padding/Margin/Border
$pixel_40: 40px;
$pixel_36: 36px;
$pixel_32: 32px;
$pixel_28: 28px;
$pixel_24: 24px;
$pixel_22: 22px;
$pixel_20: 20px;
$pixel_18: 18px;
$pixel_16: 16px;
$pixel_15: 15px;
$pixel_14: 14px;
$pixel_12: 12px;
$pixel_10: 10px;
$pixel_8: 8px;
$pixel_6: 6px;
$pixel_5: 5px;
$pixel_4: 4px;

$default_border_radius: 8px;
