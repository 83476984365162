.demo-container {
  width: 100%;
  padding: 0 20px;
  margin: auto;
  max-width: 1780px;
}

.rightAlign {
  text-align: right;
  display: block;
  width: 100%;
}

.centerAlign {
  text-overflow: ellipsis;
  text-align: center;
  font-weight: normal;
  white-space: normal;
  word-wrap: normal;
  line-height: 1.5em;
  width: 100%;
}

.cardHeader {
  background: var(--card-box-background);
}

.cardTitle {
  color: var(--color_black_white);
  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.MuiPickersArrowSwitcher-button {
  padding: 20px !important;

  svg {
    fill: #000 !important;
  }

}