@import "../../../GlobalStyles/variables.scss";
@import "../../../GlobalStyles/mixins.scss";

.proForm-layout {
  &__header,
  &__footer {
    @include proContainer;
    @include flexAcenter;
    min-height: 64px;
    margin: 4px auto;
    color: $color_black_white;
    @include mobile {
      min-height: 54px;
    }
    h2 {
      font-size: $font_m;
      font-weight: 400;
    }

    p {
      color: $color_black_white;
    }

    &__actions {
      @include flexJStart;
      gap: 1rem;

      .cancel-btn {
        width: 100px;
      }
    }
  }

  &__content {
    &__wrapper {
      max-height: calc(100dvh - 338px);
      overflow: auto;
    }

    @include proContainer;
    padding: 0.4rem 1rem 0.2rem;
  }

  &.proSettingForm,
  &.proTabGridForm,
  &.proFormGridInsideSectionLayout {
    width: 100%;
    .proSettingForm-content,
    .proTabGridForm-content {
      @include proContainer;
      padding: 1.5rem 1rem 0.2rem;
    }

    .proDetail-header {
      padding: 0.5rem 1rem;
      height: 72px;
      @include mobile {
        padding: 0.3rem 1rem;
        height: 60px;
      }
    }
  }
  &.proSettingForm {
    .proForm-layout__content__wrapper {
      max-height: calc(100dvh - 250px);
      @include mobile {
        max-height: calc(100dvh - 200px);
      }
    }
  }
}

.proFormGridInsideSectionLayout {
  padding: 1rem 0 0 1rem;
  &-wrapper {
    width: 100%;
    .rs-grid-wrapper {
      padding: 0 0 0 1rem;
      .MuiDataGrid-footerContainer {
        display: none;
      }
    }
  }
}
