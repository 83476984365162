@import "../../../GlobalStyles/mixins.scss";
@import "../../../GlobalStyles/variables.scss";

.pwc-top {
  padding: $font_xs $font_xl;
  @include flexrowacenter;
  border-radius: $default_border_radius;
  &_right {
    h5 {
      font-size: $font_xs;
      font-weight: 350;
      color: $secondary_grey;
    }
    h4 {
      font-size: $font_s;
      font-weight: 500;
      color: $dashboard_top_h2;
    }
    h3 {
      font-size: $font_s;
      font-weight: 600;
      color: $dashboard_top_h2;
    }
    h2 {
      font-size: $font_l;
      font-weight: 600;
      color: $dashboard_top_h2;
    }
  }
}
.pwc {
  &_section {
    &_top_3 {
      @include gridspace12(3, 6px);
    }
    &_top_2 {
      @include gridspace12(2, 6px);
    }
    &_top_1 {
      @include gridspace12(1, 6px);
    }
    &_footnotes {
      margin-bottom: 10px;
    }
    &_mid {
      @include gridspace12(2, 6px);
      margin-bottom: 10px;
    }
  }
}
.icon-hor-vert {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.project-job-text {
  font-size: $font_m;
  font-family: $font_family;
  color: var(--form-label-color);
}