@import "../../GlobalStyles/mixins.scss";
@import "../../GlobalStyles/variables.scss";

.tab {
  @include flexcolumn;
  margin: 0 $pixel_24 0 0;
  cursor: pointer;
  position: relative;
  padding: 0 0 $pixel_8 0;
  border: none;
  background: none;
  &_main {
    @include flexrowacenter;
    span {
      font-size: $font_s;
      font-weight: 400;
      color: $header_text;
      padding: 0 $pixel_8;
    }
  }

  &.hm-active {
    border-bottom: 2px solid $header_border_active;
    .menu-title {
      font-weight: 600;
      color: $header_text;
    }
  }

  &.drop {
    padding: 0 0 $pixel_8 0;
    border-bottom: 2px solid $primary_blue;
  }

  &_dropdown {
    position: absolute;
    top: 32px;
    left: 0;
    padding: $pixel_4;
    background: $tab_dropdown_background;
    box-shadow: $secondary_boxshadow;
    border-radius: $pixel_8;
    @include flexcolumn;
    width: max-content;
    z-index: 100;
    &_item {
      text-align: left;
      background: none;
      padding: $pixel_4 $pixel_12;
      span {
        background: none;
        font-weight: 400 !important;
        font-size: $font_s;
        color: $sub_header_text;
      }
    }
    &_item.active {
      span {
        color: $sub_header_text_active;
      }
    }
    &_item:hover {
      span {
        color: $sub_header_text_active;
      }
    }
  }
}


.subheader {
  @include flexrow;
  padding: 13px 0 0 0;
  margin: 0 12px 0 0;
  &.section-header {
    .tab_main {
      span {
        color: $section_header_text;
      }
    }
  }
}

@include mobile {
  .subheader {
    @include flexcolumn;
    padding: $pixel_12 0;
    margin: 0;
    z-index: 999;
  }
  .tab {
    margin: 0 0 $pixel_32 0;
    &_dropdown {
      margin: $pixel_12 0 0 0;
      position: relative;
      top: 0;
      width: 100%;
    }
  }
}
