@import "../../../GlobalStyles/variables.scss";
@import "../../../GlobalStyles/mixins.scss";

.new-dashboard-grid .MuiDataGrid-menuIcon {
  color: #fff !important;
}

.new-dashboard-grid {

  .MuiDataGrid-root {
    border: none;
  }

  .MuiDataGrid-columnHeaders {
    border-bottom: 1px soild #f9f9f9 !important;
    background-color: var(--dashboard-table-background-color) !important;
    color: #fff;
    .MuiDataGrid-menuIcon {
      visibility: hidden !important;
    }
  }

  .MuiDataGrid-sortIcon {
    color: #fff;
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    border: 1px solid #d6ddea;
    border-left: none;
    text-align: center;
  }

  .MuiDataGrid-columnHeader:last-child,
  .MuiDataGrid-cell:last-child {
    border-right: none;
  }

  .MuiDataGrid-row {
    border-bottom: 1px solid #d6ddea;
  }

  .MuiDataGrid-row:last-child {
    border-bottom: none;
  }

  .proGrid {
    &-header {
      background: $table_background;
      color: $color_black_white;
    }

    &-title {
      color: var(--color_black_white);
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 25px;
      @include mobile {
        font-size: 15px;
      }

      &-wrapper {
        @include flexCenterBetween;
        width: 100%;
        padding: 0.3rem 0 0.5rem;
        flex-wrap: wrap;
        gap: 1rem;

        &-with-border {
          border-top: 1px solid #adadad;
          margin-bottom: 10px;
        }

        &-no-border {
          border: none;
        }

        @include mobile {
          gap: 0.5rem;
        }
      }
    }

    &-filter {
      @include flexCenter;
      gap: 1rem;

      .date-select {
        button:not(.MuiButton-containedPrimary) {
          border: none;
          color: #0000008a !important;
          background: none !important;
        }
      }

      button:not(.MuiButton-containedPrimary) {
        border: 1px solid $secondary_border;
        font-size: 14px;
        padding: 6px 16px;
        text-transform: capitalize;
        border-radius: $default_border_radius;
        color: #35353a !important;
      }

      @include mobile {
        gap: 0.5rem;
        width: 100%;
        justify-content: end;
        flex-wrap: wrap;
      }
    }

    &-search-wrapper {
      & .MuiInputBase-root {
        border-radius: $default_border_radius;
        background: $table_search_background;
        border: 1px solid $table_search_border;
        padding: 1px 6px;
        width: 380px;

        @include mobile {
          width: 150px;
        }

        .MuiSvgIcon-root {
          color: $color_black_white;
        }

        .MuiInputBase-input {
          color: $color_black_white;
          font-weight: 400 !important;
        }

        &::before,
        &::after {
          display: none;
        }
      }
    }

    &-no-rows {
      @include flexCenter;
      color: $color_black_white;
      height: 100%;
      border-bottom: 1px solid $secondary_border;
    }
  }

  .proGrid-table-cell {
    color: $table_cell_text;
    background-color: var(--table-background-color);
    border-color: $table_border !important;
  }

  // .MuiDataGrid-row:hover {
  //   background: $table_row_hover !important;
  // }

  .MuiDataGrid {
    &-overlayWrapper {
      min-height: 44px;
    }

    &-selectedRowCount {
      display: none;
    }
  }

  &.v2 {
    .MuiDataGrid-rowCount {
      display: none;
    }
  }

  .collapsed {
    max-width: 1340px;
  }

  .export-icon {
    color: #35353a !important;

    svg {
      color: #35353a !important;
    }
  }

  .MuiDataGrid-row:nth-of-type(odd) {
    background: var(--dashboard-table-row-even-background-color) !important;
    border-bottom: 1px soild #f9f9f9;
  }

  .MuiDataGrid-row:nth-of-type(even) {
    background: var(--dashboard-table-row-even-background-color) !important;
    border-bottom: 1px soild #f9f9f9;
  }

  .MuiDataGrid-row:hover {
    background-color: none;
  }

  .MuiDataGrid-cellContent {
    color: var(--muitable-color);
  }

  .MuiTablePagination-toolbar {
    color: var(--muitable-color);
  }
}
