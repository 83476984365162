.dashboard-feed {
  background: var(--card-box-background);
  box-shadow: 0px 2px 2px 0px #00000080;
  border-radius: 8px;
  height: 100%;
  padding: 0 16px;
  &-content {
    height: calc(100% - 90px);
    @media (max-width: 768px) {
      height: unset;
    }
  }
  &-title {
    padding: 16px 0;
    border-bottom: 1px solid #b8b8b8;
    font-size: 20px;
    font-weight: 600;
    color: var(--header-title-color);
  }
  &-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    margin-top: 12px;
    background: var(--feed-option-background);
    &-left {
      display: flex;
      align-items: center;
      button {
        display: flex;
        align-items: center;
        padding: 4px;
        border: none;
        background: none;
        border-right: 1px solid #7e7e7e;
        cursor: pointer;
        svg {
          height: 18px;
        }
      }
    }
  }
  &-type {
    display: flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    &-option {
      cursor: pointer;
      padding: 4px;
      width: 76px;
      text-align: center;
      color: #0062aa;
      background: #c5e6ff;
      border: none;
      &.active {
        background: #41aeff;
        color: #fff;
      }
    }
  }
  &-list {
    margin-top: 16px;
    height: calc(100% - 70px);
    overflow: auto;
    @media (max-width: 768px) {
      height: unset;
    }
    &-item {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 16px;
      &-icon {
        display: flex;
        align-items: center;
      }
      &-detail {
        border-left: 1px solid #5a5a5a;
        padding-left: 12px;
        cursor: pointer;
        .detail-title {
          font-size: 14px;
          font-weight: 600;
          color: var(--font-color);
        }
        .detail-date {
          font-size: 14px;
          color: #666565;
        }
      }
    }
  }
}
