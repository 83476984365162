.paper {
  display: flex;
  padding: 10px;
  align-items: center;
  background-color: #f6f5ff;
}

.left-column {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e0e0e0;
  margin-left: 20px;
  padding: 20px;
}

.right-column {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
}

.section {
  width: 45%;
  padding-right: 24px;
}

.data-row {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.vertical-divider {
  margin: 0 16px;
  align-self: stretch;
}
