@import "../../../GlobalStyles/variables.scss";
@import "../../../GlobalStyles/mixins.scss";

.proFsw {
  padding: 1.8rem 1rem 1rem;
  margin-bottom: 25px;
  border: 1px solid $form_border_color_primary;
  border-radius: $default_border_radius;
  position: relative;
  width: 100%;
  &-title {
    color: $form_value_color;
    background: $body_background;
    font-weight: 500;
    font-family: $font_family;
    font-size: 16px;
    top: 0;
    position: absolute;
    transform: translate(0, -50%);
    padding: 0 5px;
    left: 1rem;
    cursor: pointer;
    transition: top 0.1s ease;
  }
  &-close {
    position: absolute;
    right: 1rem;
    top: 0.2rem;
    cursor: pointer;
    background: none;
    border: none;
  }
  &-content {
    width: 100%;
    max-height: 2000px;
    transition: max-height 0.5s ease;
  }
  &.close {
    .proFsw {
      &-close {
        top: 0.6rem;
      }
      &-content {
        max-height: 0;
        overflow: hidden;
      }
      &-title {
        top: 22px;
      }
    }
  }
}
