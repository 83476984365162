@import "../../../GlobalStyles/mixins.scss";
@import "../../../GlobalStyles/variables.scss";

.rapWage {
  .proFsw {
    padding: 1.8rem 1rem 1rem 0;
  }
  .proDetail-header-wrapper {
    border-bottom: none;
  }

  .MuiGrid-root {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .MuiTableCell-root.MuiTableCell-head {
    font-family: $font_family;
    background: $primary_background;
    padding: 0.7rem 0 0.7rem 10px;
    font-size: 14px;
    @include mobile {
      font-size: 15px;
    }
  }

  .table-head-row {
    background: #e6f5f9;
  }

  .MuiTableCell-root.MuiTableCell-body {
    padding: 0 0.8rem;
    color: $table_cell_text;
    font-weight: 400;
    font-family: $font_family;
  }
  .MuiTableContainer-root {
    overflow: hidden;
  }
}
