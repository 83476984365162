@import "../../../GlobalStyles/mixins.scss";
@import "../../../GlobalStyles/variables.scss";

.rapstatus {
  &_container {
    width: 100%;
    @include flexCenter;
  }
  &_section {
    width: 100%;
    &_top {
      @include gridspace12(4, 12px);
    }
    &_bottom {
      width: 100%;
      padding: 20px 0 0 0;
    }
  }
}

@include mobile {
  .rapstatus {
    &_container {
      padding: 0;
    }
    &_section {
      &_top {
        @include gridspace12(1, 12px);
      }
      &_center {
        @include gridspace12(1, 12px);
        &_sec {
          &_content {
            @include flexcolumn;
            &_left {
              width: 100%;
            }
            &_right {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
