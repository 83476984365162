@import "../../../GlobalStyles/variables.scss";

.rdg-example {
    min-block-size: 50vh !important;
}

.certified-payroll {

    thead {
        tr {
            background: $certified_payroll_table_bg;
        }

        th {
            padding: 10px 16px;
            color: $certified_payroll_table_cell_text;
        }
    }

    tr {
        background: $certified_payroll_table_row_bg;
    }

    td {
        padding: 10px 16px !important;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
    .no-data {
        color: var(--color_black_white);
    }

    th {
        color: $certified_payroll_table_cell_text;
    }

    &_cell {
        display: flex;

        &_button {
            color: #00b0ee;
            cursor: pointer;
        }

        .button1 {
            color: #00b0ee;
            cursor: pointer;
            padding: 2px 0 0 0;
        }
    }

    .MuiPaginationItem-page.Mui-selected {
        background: $pagination_background_active !important;
        color: $pagination_text_active !important;
      }

      .MuiPaginationItem-page {
        background: $pagination_background !important;
        color: $pagination_text !important;
      }

      .MuiPaginationItem-root.MuiPaginationItem-text.Mui-disabled {
        color: $pagination_text !important;
      }

      .MuiPaginationItem-root.MuiPaginationItem-text:last-child {
        color: $pagination_text !important;
      }

}