@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $webkit_scroller;
}
::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: $webkit_scroller;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: $webkit_scroller_thumb;
}

[data-theme-ui="dark"] {
  ::-webkit-scrollbar {
    background-color: $webkit_scroller;
  }
  ::-webkit-scrollbar-track {
    background-color: $webkit_scroller;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $webkit_scroller_thumb;
  }
}

body {
  padding: 0%;
  max-width: 2600px;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: $font_family;
  width: 100%;
  background-color: $body_background;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: $scroll_background;
  }
  ::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: $scroll_background;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: $scroll_thumb;
  }
}

