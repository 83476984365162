.dashboard-journey {
  margin-bottom: 40px;
  &-wrapper {
    overflow: hidden;
  }
  &.no-slick {
    display: flex;
    gap: 16px;
    .dashboard-journey-card {
      flex: 1;
    }
  }
  .slick-slide {
    padding: 0 8px;
  }

  .slick-list {
    margin: 0 -8px;
  }
  &-card {
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    &-content {
      position: relative;
      z-index: 2;
      padding: 12px;
    }
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }
    &-subtitle {
      font-size: 14px;
      color: #fff;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: no-repeat;
  }
}
