@import "../../GlobalStyles/mixins.scss";
@import "../../GlobalStyles/variables.scss";

.proLogin {
  width: 100%;
  max-width: 400px;
  &_form {
    &_input {
      padding-top: 24px;
    }
    &_button {
      padding-top: 24px;
    }
    &_link {
      text-align: center;
      padding-top: 24px;
      a {
        color: $pre_login_link_text;
      }
    }
    &_info {
      padding-top: 12px;
      font-size: $form_font_size;
      color: $pre_login_primary_text;
    }
    &_error {
      margin: 12px auto -12px;
      text-align: center;
      &.needBottomSpace {
        margin-bottom: 0;
      }
    }
  }
  &_dialog {
    .MuiDialog-paper {
      overflow: visible;
    }
    .proLogin_dialog_avatar {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      background: $primary_btn_bg;
    }
  }
}
