@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/mixins.scss";

.userPermissionWrapper {
  max-height: calc(100dvh - 332px);
  @include mobile {
    max-height: calc(100dvh - 268px);
  }
  .MuiDataGrid-virtualScroller {
    max-height: calc(100dvh - 390px);
    @include mobile {
      max-height: calc(100dvh - 326px);
    }
  }
}
